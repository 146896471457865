import { getCountryIso } from 'src/modules/challenges/api/weekly';
import { getBearer } from 'src/utils/api';
import { store } from '../../../index';

export const apiFetch = (endpoint, config) => {
  const tweakedConfig = config;
  const { language } = store.getState().app.currentCountry || 'en';
  const currentCountry = localStorage.getItem('currentCountry');

  let jsonCurrentCountry;
  let fallbackLanguage;

  try {
    if (currentCountry) {
      jsonCurrentCountry = JSON.parse(currentCountry);
    }
    if (currentCountry && jsonCurrentCountry.hasOwnProperty('language')) {
      fallbackLanguage = jsonCurrentCountry.language;
    }
  } catch (e) {
    console.log(e);
  }

  if (!tweakedConfig.hasOwnProperty('headers')) {
    tweakedConfig.headers = {};
    tweakedConfig.headers['Accept-Language'] = language || fallbackLanguage;
  } else {
    tweakedConfig.headers['Accept-Language'] = language || fallbackLanguage;
  }

  if (!tweakedConfig.headers['Content-Type']) {
    tweakedConfig.headers['Content-Type'] = 'application/json';
  }

  if (tweakedConfig.headers['Content-Type'] === 'multipart/form-data') {
    delete tweakedConfig.headers['Content-Type'];
  }

  return fetch(endpoint, tweakedConfig);
};

export const source = process.env.REACT_APP_PRODUCTION === 'TRUE'
  ? 'https://api.pupvote.com/v1'
  : 'https://pickmypaw-api.abz.dev/v1';
export const currentSource = source;

const sourceWS = process.env.REACT_APP_PRODUCTION === 'TRUE'
  ? 'https://ws.pupvote.com'
  : 'https://pickmypaw-ws.abz.dev';
export const currentSourceWS = sourceWS;

export const handleSearch = (value) => fetch(`${source}/search?query=${value}`);

export const handleSearchPage = (
  requestName = 1,
  pageNumber,
  inputValue,
  perPage,
) => fetch(
  `${source}/search/${requestName}?page=${pageNumber}&query=${inputValue}&limit=${perPage}`,
);

export const getUserData = (apiToken) => apiFetch(`${source}/users/me`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${apiToken}`,
    'X-Timezone':
      Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : null,
  },
});

export const deleteUser = (apiToken) => apiFetch(`${source}/users/me`, {
  method: 'DELETE',
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
});

export const getUserPost = (limit, offset) => apiFetch(`${source}/users/me/posts?limit=${limit}&offset=${offset}`, {
  method: 'GET',
  headers: {
    ...getBearer(),
  },
});

export const getUserContestants = (limit, offset, petType) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  const isDog = petType?.includes('dog');
  const isCat = petType?.includes('cat');

  const typeQuery = isDog || isCat ? `&type=${isDog ? 'dog' : 'cat'}` : '';

  return apiFetch(
    `${source}/users/me/posts/contestants?limit=${limit}&offset=${offset}${typeQuery}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    },
  );
};

export const registerUser = (data) => apiFetch(`${source}/users`, {
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  body: data,
});

export const restorerUser = (apiToken) => {
  const data = {
    userToken: apiToken,
  };
  return apiFetch(`${source}/users/me/restore`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const getPostInfo = (id) => {
  const currentCountry = JSON.parse(
    window.localStorage.getItem('currentCountry'),
  );
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${apiToken}`,
  };

  if (currentCountry) headers['X-Country'] = currentCountry.code;

  return apiFetch(`${source}/posts/${id}`, {
    method: 'GET',
    headers,
  });
};

export const getPostVotes = (id) => apiFetch(`${source}/posts/${id}/votes`, {
  method: 'GET',
});

export const getSimilarPets = (postID) => {
  const currentCountry = JSON.parse(
    window.localStorage.getItem('currentCountry'),
  );

  const headers = {
    'Content-Type': 'application/json',
  };
  if (currentCountry) headers['X-Country'] = currentCountry.code;

  return apiFetch(`${source}/posts/${postID}/similar/?limit=3`, {
    method: 'GET',
    headers,
  });
};

export const getComments = (postID, offset) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/posts/${postID}/comments?offset=${offset}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const getReplies = (postID, parentId, offset, limit) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/posts/${postID}/comments?parent_id=${parentId}&offset=${offset}&limit=${
      limit || 10
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    },
  );
};

export const postComment = (postID, message, apiToken, parentId) => {
  const parentComment = parentId ? `?parent_id=${parentId}` : '';
  return apiFetch(`${source}/posts/${postID}/comments${parentComment}`, {
    method: 'POST',
    body: JSON.stringify({ text: message }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const deleteComment = (postID, id, apiToken) => apiFetch(`${source}/posts/${postID}/comments/${id}`, {
  method: 'DELETE',
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
});

export const voteToPet = (apiToken, id, captchaResult) => {
  if (captchaResult) {
    return apiFetch(`${source}/posts/${id}/votes`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify({
        captcha: captchaResult,
      }),
    });
  }
  return apiFetch(`${source}/posts/${id}/votes`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const getPayments = (postID) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;
  const iso = getCountryIso();

  return apiFetch(`${source}/posts/${postID}/votes/packages`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};

export const postPayments = (apiToken, postID, paymentId, stripeToken) => apiFetch(`${source}/posts/${postID}/votes/payment`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${apiToken}`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    paymentId,
    stripeToken,
  }),
});

export const paymentInit = (apiToken, postID, photoID, body) => {
  if (photoID) {
    const iso = getCountryIso();

    return apiFetch(
      `${source}/posts/${postID}/photos/${photoID}/votes/payments/init`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'Content-Type': 'application/json',
          'x-country': iso,
        },
        body: JSON.stringify(body),
      },
    );
  }
  return apiFetch(`${source}/posts/${postID}/votes/payment/init`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const paymentConfirm = (postID, apiToken, paymentIntentId) => apiFetch(`${source}/posts/${postID}/votes/payment/confirm`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${apiToken}`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    paymentIntentId,
  }),
});

export const paymentCancel = (postID, apiToken, paymentIntentId) => apiFetch(`${source}/posts/${postID}/votes/payment/cancel`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${apiToken}`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    paymentIntentId,
  }),
});

export const getContestType = (type, locationType, locationId) => {
  const currentCountry = JSON.parse(
    window.localStorage.getItem('currentCountry'),
  );
  const headers = {
    'Content-Type': 'application/json',
  };

  if (currentCountry) headers['X-Country'] = currentCountry.code;

  return apiFetch(`${source}/contests/${locationType}/${locationId}/${type}`, {
    method: 'GET',
    headers,
  });
};

export const getContestTypePets = (
  type,
  limit,
  offset,
  locationType,
  locationId,
) => apiFetch(
  `${source}/contests/${locationType}/${locationId}/${type}/pets?limit=${limit}&offset=${offset}`,
  {
    method: 'GET',
  },
);

export const getContestWinners = async (locationType, locationId) => apiFetch(`${source}/contests/${locationType}/${locationId}/winners`, {
  method: 'GET',
});

export const getLeaderboardType = (
  type,
  limit,
  offset,
  locationType,
  locationId,
) => {
  const currentCountry = JSON.parse(
    window.localStorage.getItem('currentCountry'),
  );
  const headers = {
    'Content-Type': 'application/json',
  };

  if (currentCountry) headers['X-Country'] = currentCountry.code;

  return apiFetch(
    `${source}/contests/${locationType}/${locationId}/${type}/leaderboard?limit=${limit}&offset=${offset}`,

    {
      method: 'GET',
      headers,
    },
  );
};

export const getLeaderboardPets = (contestID, limit, offset) => apiFetch(
  `${source}/contests/${contestID}/leaderboard?limit=${limit}&offset=${offset}`,
  {
    method: 'GET',
  },
);

export const getLeaderboardWinners = (
  limit,
  offset,
  contestID,
  locationType,
  locationId,
) => {
  const currentCountry = JSON.parse(
    window.localStorage.getItem('currentCountry'),
  );

  const headers = {
    'Content-Type': 'application/json',
  };
  if (currentCountry) headers['X-Country'] = currentCountry.code;

  return apiFetch(
    `${source}/contests/${locationType}/${locationId}/winners/leaderboard?limit=${limit}&offset=${offset}${
      contestID ? `&contest_id=${contestID}` : ''
    }`,
    {
      method: 'GET',
      headers,
    },
  );
};

export const participateInContest = (apiToken, postID) => apiFetch(`${source}/users/me/posts/${postID}/participate`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${apiToken}`,
    'Content-Type': 'application/json',
  },
});

export const getPostContest = (postID, contestID) => apiFetch(`${source}/posts/${postID}/contests/${contestID}`, {
  method: 'GET',
});

export const getPostCurrentContests = (postID) => apiFetch(`${source}/posts/${postID}/contests`, {
  method: 'GET',
});

export const getWinnersCard = () => apiFetch(`${source}/winnersCards`, {
  method: 'GET',
});

export const getUserNotification = (apiToken, limit, offset) => apiFetch(`${source}/users/me/notifications?limit=${limit}&offset=${offset}`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
});

export const readNotification = (apiToken) => apiFetch(`${source}/users/me/notifications/read`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${apiToken}`,
    'Content-Type': 'application/json',
  },
});

export const sendEmailMessage = (body) => apiFetch(`${source}/sendMail`, {
  method: 'POST',
  body: JSON.stringify(body),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getPetsForSock = (apiToken) => apiFetch(`${source}/users/me/printBark`, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
});

export const getBreeds = (type) => apiFetch(`${source}/posts/breeds?petType=${type}`, {
  method: 'GET',
});

export const getBreedsPets = (breedName, offset, limit) => apiFetch(
  `${source}/similarBreeds?breed=${breedName}&offset=${offset}&limit=${limit}`,
  {
    method: 'GET',
  },
);

export const getReasonsForReport = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  if (!apiToken) return { status: 401 };
  return apiFetch(`${source}/reports/comments/reasons`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const sendReport = (id, reason) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  if (!apiToken) return { status: 401 };
  return apiFetch(`${source}/reports/comments/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify({ reason }),
  });
};

export const getCurrentCountry = () => apiFetch(`${source}/dictionaries/countries/current`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getCountries = (withContests = false, offset = 0, limit = 100) => apiFetch(
  `${source}/dictionaries/countries/${withContests ? 'contests' : null}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  },
);

export const getRegions = (countryId, limit = 100) => apiFetch(
  `${source}/dictionaries/regions?countryId=${countryId}&limit=${limit}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  },
);

export const getCountriesGlobalContest = () => apiFetch(
  `${source}/dictionaries/countries/contests`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  },
);

export const login = (type, code) => {
  const ref = localStorage.getItem('ref');
  const partner = localStorage.getItem('partner');
  const body = {
    type,
    code,
  };

  if (partner || ref) {
    body.ref = partner || ref;
  }

  return apiFetch(`${source}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const customLoginEmail = (email, changeField, captcha) => {
  const mirror = localStorage.getItem('mirror');
  const ref = localStorage.getItem('ref');
  const partner = localStorage.getItem('partner');
  const body = {
    email,
    action: changeField ? 'update' : 'auth',
  };

  if (captcha) {
    body.captcha = captcha;
  }

  if (partner || ref) {
    body.ref = partner || ref;
  }

  return apiFetch(`${source}/login/init`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Mirror: mirror,
    },
    body: JSON.stringify(body),
  });
};

export const customLoginEmailConfirm = (hash) => apiFetch(`${source}/login/confirm`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ hash }),
});

export const logoutApi = (apiToken) => apiFetch(`${source}/logout`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${apiToken}`,
  },
});

export const getUserCards = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/cards`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const getCurrentContest = () => apiFetch(`${source}/dictionaries/countries/current`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getCoupons = (postID, offset = 0, limit = 100) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/posts/${postID}/votes/coupons?offset=${offset}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    },
  );
};

export const useCoupon = (postID, couponID) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/posts/${postID}/votes/coupons/${couponID}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const getPaymentMethods = () => apiFetch(`${source}/dictionaries/paymentMethods`);

export const getTestimonials = (offset = 0, limit = 9, countryId, random) => {
  let query = `${source}/testimonials?offset=${offset}&limit=${limit}`;
  if (countryId) {
    query += `&countryId=${countryId}`;
  }
  if (random) {
    query += '&random=1';
  }
  return apiFetch(query, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postReview = (rating, text, token) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;
  return apiFetch(`${source}/reviews`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify({ rating, text, token }),
  });
};

export const getUserByHash = (hash) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  if (apiToken) {
    return apiFetch(`${source}/reviews/${hash}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }

  return apiFetch(`${source}/reviews/${hash}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteSavedCard = (fingerprint) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/cards/${fingerprint}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const getAchievements = (offset = 0, limit = 50) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/users/me/achievements?offset=${offset}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    },
  );
};

export const postAchievement = (postId, achievementId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/users/me/posts/${postId}/achievements/${achievementId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify({
        postId,
        achievementId,
      }),
    },
  );
};

export const postUsersMeEvents = (code) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify({
      code,
    }),
  });
};

export const patchTutorialState = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/pupswipe/tutorial`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const getPupswipeCards = (isFresh, countryId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/pupswipe/cards${
      isFresh ? `?fresh=1&countryId=${countryId}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    },
  );
};

export const patchPupswipeAction = (pupswipeCardId, action) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/pupswipe/cards/${pupswipeCardId}/${action}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const postPetPupswipe = (pupswipeCardId, postId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/pupswipe/cards/${pupswipeCardId}/posts/${postId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    },
  );
};

export const postFbPixel = (data) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/pixel/make-event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(data),
  });
};

export const getDictionariesCurrencyExchange = () => apiFetch(`${source}/dictionaries/currencyExchange`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});
