import {
  SET_SEARCH_TYPE,
  SET_SEARCH_QUERY,
  FETCH_SEARCH_SUCCESS,
  NEXT_SEARCH,
  NEXT_PAGE_START,
  RESET_SEARCH,
  CHANGE_INPUT_VALUE,
  FETCH_SEARCH_START,
} from '../actionTypes';
import { searchFactory, newState } from '../utils';

const initialState = {
  query: '',
  type: 'owners',
  pets: [],
  owners: [],
  petsNextLink: '',
  ownersNextLink: '',
  isLoading: false,
  inputValue: '',
  emptyResultPets: false,
  emptyResultOwners: false,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TYPE:
      return newState(state, { type: action.payload });
    case SET_SEARCH_QUERY:
      return newState(state, { query: action.payload });
    case FETCH_SEARCH_SUCCESS:
      const newResult = searchFactory(
        action.payload.data,
        action.payload.requestName,
      );
      let nextLink = 'ownersNextLink';
      if (newResult.type === 'pets') {
        nextLink = 'petsNextLink';
      }
      let emptyResult = '';
      let emptyResultBool;
      if (action.payload.data.length === 0) {
        if (newResult.type === 'pets') {
          emptyResult = 'emptyResultPets';
          emptyResultBool = true;
        } else {
          emptyResult = 'emptyResultOwners';
          emptyResultBool = true;
        }
      } else if (newResult.type === 'pets') {
        emptyResult = 'emptyResultPets';
        emptyResultBool = false;
      } else {
        emptyResult = 'emptyResultOwners';
        emptyResultBool = false;
      }
      return newState(state, {
        [newResult.type]: newResult.data,
        [nextLink]: action.payload.links.nextLink,
        [emptyResult]: emptyResultBool,
        query: decodeURIComponent(
          action.payload.query.replace(/[!"№;%:?*()]/g, ''),
        ),
        isLoading: false,
      });
    case NEXT_SEARCH:
      const nextResult = searchFactory(action.payload.data, state.type);
      let nextLinkResult = 'ownersNextLink';
      if (state.type === 'pets') {
        nextLinkResult = 'petsNextLink';
      }
      return newState(state, {
        [state.type]: [...state[state.type], ...nextResult.data],
        [nextLinkResult]: action.payload.links.nextLink,
        isLoading: false,
      });
    case NEXT_PAGE_START:
      return newState(state, { isLoading: true });
    case FETCH_SEARCH_START:
      return newState(state, { isLoading: true });
    case CHANGE_INPUT_VALUE:
      return newState(state, { query: decodeURIComponent(action.payload) });
    case RESET_SEARCH:
      return newState(state, {
        pets: [],
        owners: [],
        petsNextLink: '',
        ownersNextLink: '',
        emptyResultPets: false,
        emptyResultOwners: false,
      });
    default:
      return state;
  }
};

export default searchReducer;
