import { produce } from 'immer';
import { LOADING_STATES } from '../../../utils/maps';
import { STREAKS_CONSTANTS } from '../constants/streaks';

const initialState = {
  leaveStreakOpenedId: null,
  cancelInviteOpenedId: null,
  rejectInviteOpenedId: null,
  historyRestoreOpenedId: null,

  pupstreaks: {
    data: [],
    status: LOADING_STATES.LOADING,
    error: null,
  },
  history: {
    data: [],
    status: LOADING_STATES.LOADING,
    error: null,
    page: 0,
    isMore: true,
    firstLoad: true,
  },
  sent: {
    data: [],
    status: LOADING_STATES.LOADING,
    error: null,
  },
  inbox: {
    data: [],
    status: LOADING_STATES.LOADING,
    error: null,
    page: 0,
    isMore: true,
    firstLoad: true,
  },
  stats: {
    data: {},
    status: LOADING_STATES.LOADING,
    error: null,
  },
};

const calculateCycle = (days) => {
  const cycle = Math.floor(days / 30);

  if (days % 30 === 0) {
    if (cycle - 1 <= 0) {
      return 0;
    }

    return cycle;
  }

  return cycle;
};

const streaksReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case STREAKS_CONSTANTS.setPupstreaksData:
      draft.pupstreaks.data = action.payload;
      break;
    case STREAKS_CONSTANTS.setPupstreaksStatus:
      draft.pupstreaks.status = action.payload;
      break;
    case STREAKS_CONSTANTS.setPupstreaksError:
      draft.pupstreaks.error = action.payload;
      break;
    case STREAKS_CONSTANTS.resetPupstreaks:
      draft.pupstreaks = initialState.pupstreaks;
      break;
    case STREAKS_CONSTANTS.filterPupstreakItemById:
      draft.pupstreaks.data = draft.pupstreaks.data.filter(item => item.id !== action.payload);
      break;
    case STREAKS_CONSTANTS.setPairedPetVoted:
      draft.pupstreaks.data = draft.pupstreaks.data.map(item => {
        if (item.id === action.payload.id) {
          const completedDays = item.userPet.isVoted && action.payload.isVoted ? item.streaks.completedDays + 1 : item.streaks.completedDays;

          return {
            ...item,
            paired: {
              ...item.paired,
              pet: {
                ...item.paired.pet,
                isVoted: action.payload.isVoted,
              },
            },
            streaks: {
              ...item.streaks,
              completedDays,
              completedCycles: calculateCycle(completedDays),
            },
          };
        }

        return item;
      });
      break;
    case STREAKS_CONSTANTS.incrementCompletedDays:
      draft.pupstreaks.data = draft.pupstreaks.data.map(item => {
        if (item.id === action.payload) {
          const leftToVote = new Date();
          leftToVote.setDate(leftToVote.getDate() + 1);
          const { completedDays } = item.streaks;

          const cycle = calculateCycle(completedDays + 1);

          return {
            ...item,
            paired: {
              ...item.paired,
              pet: {
                ...item.paired.pet,
                isVoted: false,
              },
            },
            userPet: {
              ...item.userPet,
              isVoted: false,
            },
            streaks: {
              ...item.streaks,
              completedDays,
              completedCycles: cycle,
              leftToVote: leftToVote.toUTCString(),
              nextPrize: {
                ...item.streaks.nextPrize,
                votes: cycle === 0 ? 1000 : (cycle + 1) * 1000,
              },
            },
          };
        }

        return item;
      });
      break;
    case STREAKS_CONSTANTS.setPupstreakReminded:
      draft.pupstreaks.data = draft.pupstreaks.data.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            userPet: {
              ...item.userPet,
              isSendRemind: action.payload.reminded,
            },
          };
        }

        return item;
      });
      break;

    case STREAKS_CONSTANTS.resetHistory:
      draft.history = initialState.history;
      break;
    case STREAKS_CONSTANTS.setHistoryFirstLoad:
      draft.history.firstLoad = action.payload;
      break;
    case STREAKS_CONSTANTS.setHistoryStatus:
      draft.history.status = action.payload;
      break;
    case STREAKS_CONSTANTS.setHistoryError:
      draft.history.error = action.payload;
      break;
    case STREAKS_CONSTANTS.setHistoryIsMore:
      draft.history.isMore = action.payload;
      break;
    case STREAKS_CONSTANTS.setHistoryData:
      draft.history.data = [...state.history.data, ...action.payload];
      break;
    case STREAKS_CONSTANTS.setHistoryPage:
      draft.history.page = action.payload;
      break;
    case STREAKS_CONSTANTS.setHistoryRestoreOpenedId:
      draft.historyRestoreOpenedId = action.payload;
      break;
    case STREAKS_CONSTANTS.filterHistoryItemById:
      draft.history.data = draft.history.data.filter(item => item.id !== action.payload);
      break;

    case STREAKS_CONSTANTS.resetSent:
      draft.sent = initialState.sent;
      break;
    case STREAKS_CONSTANTS.setSentStatus:
      draft.sent.status = action.payload;
      break;
    case STREAKS_CONSTANTS.setSentError:
      draft.sent.error = action.payload;
      break;
    case STREAKS_CONSTANTS.setSentData:
      draft.sent.data = action.payload;
      break;
    case STREAKS_CONSTANTS.filterSentItemById:
      draft.sent.data = draft.sent.data.filter(item => item.id !== action.payload);
      break;

    case STREAKS_CONSTANTS.resetInbox:
      draft.inbox = initialState.inbox;
      break;
    case STREAKS_CONSTANTS.setInboxFirstLoad:
      draft.inbox.firstLoad = action.payload;
      break;
    case STREAKS_CONSTANTS.setInboxStatus:
      draft.inbox.status = action.payload;
      break;
    case STREAKS_CONSTANTS.setInboxError:
      draft.inbox.error = action.payload;
      break;
    case STREAKS_CONSTANTS.setInboxIsMore:
      draft.inbox.isMore = action.payload;
      break;
    case STREAKS_CONSTANTS.setInboxData:
      draft.inbox.data = [...state.inbox.data, ...action.payload];
      break;
    case STREAKS_CONSTANTS.setInboxPage:
      draft.inbox.page = action.payload;
      break;
    case STREAKS_CONSTANTS.filterInboxItemById:
      draft.inbox.data = draft.inbox.data.filter(item => item.id !== action.payload);
      break;

    case STREAKS_CONSTANTS.setStatsData:
      draft.stats.data = action.payload;
      break;
    case STREAKS_CONSTANTS.setStatsStatus:
      draft.stats.status = action.payload;
      break;
    case STREAKS_CONSTANTS.setStatsError:
      draft.stats.error = action.payload;
      break;
    case STREAKS_CONSTANTS.resetStats:
      draft.stats = initialState.stats;
      break;

    case STREAKS_CONSTANTS.setLeaveStreakOpenedId:
      draft.leaveStreakOpenedId = action.payload;
      break;

    case STREAKS_CONSTANTS.setCancelInviteOpenedId:
      draft.cancelInviteOpenedId = action.payload;
      break;

    case STREAKS_CONSTANTS.setRejectInviteOpenedId:
      draft.rejectInviteOpenedId = action.payload;
      break;

    default:
      break;
  }
});

export default streaksReducer;
