import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCurrencyExchange } from '../../../../actions/app';

const CurrencyExchangeLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrencyExchange());
  }, []);
  return (
    <div />
  );
};

export default CurrencyExchangeLoader;
