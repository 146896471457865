import {
  FETCH_PETS_SUCCESS,
  RESETE_PETS,
  FETCH_PETS_START,
  NEXT_PAGE_SUCCESS,
  SWITCH_PAGE,
  SHOW_NEXT_PAGE,
  SHOW_PETS,
  SET_FIRST_TIME,
  RESETE_STATE,
  NEXT_PAGE_PETS_LOADED,
} from '../actionTypes';
import { splitArrayToChunk, newState } from '../utils';

let pents = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

pents = pents.map((el) => ({
  image: '',
  countVotes: '',
  petName: '',
  userNickname: '',
  isNew: false,
}));

const initialState = {
  page: 1,
  order: 'recent',
  perPage: null,
  pets: [[...pents]],
  isLoading: false,
  nextPageLoaded: false,
  column: 1,
  oldPets: [],
  firstTime: true,
  offset: 0,
  disabledPage: false,
};

const petsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PETS_SUCCESS:
      let { column } = action.payload;
      if (!column) {
        column = state.column;
      }
      let oldPets = [...state.pets];
      if (action.payload.order !== state.order) {
        oldPets = [];
      }
      let arrWithId = [];
      oldPets.forEach((page) => {
        if (page) {
          const pageArrId = page.map((element) => element.id);
          arrWithId = [...arrWithId, ...pageArrId];
        }
      });
      let newPetsCopy = [...action.payload.pets];
      newPetsCopy = newPetsCopy.filter((element) => {
        if (arrWithId.includes(element.id)) {
          return false;
        }
        return true;
      });
      if (action.payload.page > 1) {
        let block = '';
        try {
          const petBlock = oldPets[action.payload.page - 2][
            oldPets[action.payload.page - 2].length - 1
          ];
          if (petBlock && petBlock.randomBlock === 'list') {
            block = 'cta';
          } else if (petBlock && petBlock.randomBlock === 'cta') {
            block = 'winners';
          } else if (petBlock && petBlock.randomBlock === 'weekly') {
            block = 'weekly';
          } else {
            block = 'list';
          }
        } catch (e) {
          block = 'list';
        }
        if (newPetsCopy[newPetsCopy.length - 1]) {
          newPetsCopy[newPetsCopy.length - 1].randomBlock = block;
        }
      }
      oldPets[action.payload.page - 1] = newPetsCopy;
      const { page } = action.payload;

      const processedArrayNextPage = oldPets.flat().map((item, index) => {
        if (index % 32 === 0 && index > 0) {
          return {
            ...item,
            randomBlock: 'weekly',
          };
        }

        if (index % 8 && action.payload.page === 1) {
          return {
            ...item,
            randomBLock: 'dogster',
          };
        }

        return item;
      });

      const chankedArrayNextPage = splitArrayToChunk(
        processedArrayNextPage,
        action.payload.perPage,
      );
      return newState(state, {
        pets: chankedArrayNextPage,
        isLoading: false,
        page,
        order: action.payload.order,
        perPage: action.payload.perPage,
        column,
        offset: action.payload.offset,
        disabledPage: action.payload.pets.length === 0,
      });
    case RESETE_PETS:
      return newState(state, {
        oldPets: state.pets,
        pets: [[]],
        page: 1,
        isLoading: true,
      });
    case RESETE_STATE:
      return initialState;
    case SHOW_PETS:
      return newState(state, {
        ...state,
        pets: state.oldPets,
        page: 1,
        isLoading: false,
      });
    case FETCH_PETS_START:
      return {
        ...state,
        isLoading: true,
        nextPageLoaded: false,
      };
    case NEXT_PAGE_SUCCESS:
      const olDPets = [...state.pets];
      const newPets = splitArrayToChunk(action.payload.pets, state.perPage);
      newPets.forEach((element, index) => {
        let block = '';
        if (index === 0) {
          block = 'cta';
        } else if (index === 1) {
          block = 'winners';
        } else {
          block = 'list';
        }
        let arrWithId = [];
        olDPets.forEach((pageNumber) => {
          if (pageNumber) {
            const pageArrId = pageNumber.map((element1) => element1.id);
            arrWithId = [...arrWithId, ...pageArrId];
          }
        });

        let newPetsCopy = [...element];
        newPetsCopy = newPetsCopy.filter((element2) => {
          if (arrWithId.includes(element2.id)) {
            return false;
          }
          return true;
        });
        if (newPetsCopy[newPetsCopy.length - 1]) {
          newPetsCopy[newPetsCopy.length - 1].randomBlock = block;
        }
        olDPets[index + 1] = newPetsCopy;
      });

      const processedArray = olDPets.flat().map((item, index) => {
        if (index % 32 === 0 && index > 0) {
          return {
            ...item,
            randomBlock: 'weekly',
          };
        }

        return item;
      });
      const chankedArray = splitArrayToChunk(processedArray, state.perPage);

      return newState(state, {
        pets: chankedArray,
        isLoading: false,
        offset: action.payload.offset,
      });
    case NEXT_PAGE_PETS_LOADED:
      return newState(state, {
        nextPageLoaded: action.payload,
      });
    case SWITCH_PAGE:
      return newState(state, {
        pets: [...state.pets, ...state.nextPage],
        isLoading: false,
      });
    case SHOW_NEXT_PAGE:
      return newState(state, {
        page: state.page + 1,
      });
    case SET_FIRST_TIME:
      return newState(state, {
        firstTime: false,
      });
    default:
      return state;
  }
};

export default petsReducer;
