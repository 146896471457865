export const SET_APP_NEED_UPDATE = 'SET_APP_NEED_UPDATE';
export const SETCOUNTRIES = 'SETCOUNTRIES';
export const SET_CURRENT_COUNTRY = 'SET_CURRENT_COUNTRY';
export const SETREGIONS = 'SETREGIONS';
export const SET_CURRENT_REGION = 'SET_CURRENT_REGION';
export const SETBROWSER = 'SETBROWSER';
export const SETDEVICE = 'SETDEVICE';
export const SETIPHONEX = 'SETIPHONEX';
export const SETSOURCES = 'SETSOURCES';
export const TOGGLE_MODAL_LOGIN = 'TOGGLE_MODAL_LOGIN';
export const REDIRECT_WITH_MODAL = 'REDIRECT_WITH_MODAL';
export const REMOVE_TARGET = 'REMOVE_TARGET';
export const FETCH_PETS_START = 'FETCH_PETS_START';
export const FETCH_PETS_SUCCESS = 'FETCH_PETS_SUCCESS';
export const CHANGE_SORTING = 'CHANGE_SORTING';
export const RESETE_PETS = 'RESETE_PETS';
export const NEXT_PAGE_SUCCESS = 'NEXT_PAGE_SUCCESS';
export const NEXT_PAGE_PETS_LOADED = 'NEXT_PAGE_PETS_LOADED';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const SWITCH_PAGE = 'SWITCH_PAGE';
export const SHOW_NEXT_PAGE = 'SHOW_NEXT_PAGE';
export const SHOW_PETS = 'SHOW_PETS';
export const SET_FIRST_TIME = 'SET_FIRST_TIME';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const FETCH_SEARCH_START = 'FETCH_SEARCH_START';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const NEXT_SEARCH = 'NEXT_SEARCH';
export const NEXT_PAGE_START = 'NEXT_PAGE_START';
export const RESET_SEARCH = 'RESET_SEARCH';
export const CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const SUBMIT_ACCOUNT = 'SUBMIT_ACCOUNT';
export const RESETE_ACCOUNT = 'RESETE_ACCOUNT';
export const TRIGGER_SNACK_BAR = 'TRIGGER_SNACK_BAR';
export const TRIGGER_PRELOAD_POPUP = 'TRIGGER_PRELOAD_POPUP';
export const TOGGLE_RESTORE = 'TOGGLE_RESTORE';
export const SET_PET_EDIT = 'SET_PET_EDIT';
export const TRY_TO_CHANGE_PAGE = 'TRY_TO_CHANGE_PAGE';
export const SET_UNAUTHORIZED_COMMENT = 'SET_UNAUTHORIZED_COMMENT';
export const SET_PREV_ROUTE = 'SET_PREV_ROUTE';
export const MY_ACCOUNT_CHANGED = 'MY_ACCOUNT_CHANGED';
export const SET_ADD_PET_CARD_INFO = 'SET_ADD_PET_CARD_INFO';
export const TRY_TO_LOGOUT = 'TRY_TO_LOGOUT';
export const RESETE_STATE = 'RESETE_STATE';
export const SET_IS_PRODUCTION = 'SET_IS_PRODUCTION';
export const SET_USER_ADBLOCK = 'SET_USER_ADBLOCK';
export const TRIGGER_MESSENGER_POPUP = 'TRIGGER_MESSENGER_POPUP';
export const SET_PAYMENT_RESULT = 'SET_PAYMENT_RESULT';

export const SET_TESTIMONIALS_PAGE = '@testimonials/setPage';
export const SET_TESTIMONIALS_IS_LOADING = '@testimonials/setIsLoading';
export const SET_TESTIMONIALS_IS_LOADED = '@testimonials/setIsLoaded';
export const SET_TESTIMONIALS_META = '@testimonials/setMeta';
export const SET_TESTIMONIALS_DATA = '@testimonials/setData';
export const RESET_TESTIMONIALS = '@testimonials/reset';
export const SET_TESTIMONIALS_AMOUNT = '@testimonials/setAmount';
export const SET_TESTIMONIALS_FIRST_LOAD = '@testimonials/setFirstLoad';

export const SET_UNSUBSCRIBE_STEP = 'SET_UNSUBSCRIBE_STEP';
export const SET_ACHIEVEMENTS_MODAL = 'SET_ACHIEVEMENTS_MODAL';

export const SET_ACHIEVEMENTS_DATA = 'SET_ACHIEVEMENTS_DATA';
export const SET_ACHIEVEMENTS_STATE = 'SET_ACHIEVEMENTS_STATE';
export const UPDATE_ACHIEVEMENTS_DATA = 'UPDATE_ACHIEVEMENTS_DATA';
export const RECEIVED_ACHIEVEMT = 'RECEIVED_ACHIEVEMT';

export const SET_VOTE_POPUP = 'SET_VOTE_POPUP';

export const SET_UNLOCKED_ACHIEVEMENT_OPENED = 'SET_UNLOCKED_ACHIEVEMENT_OPENED';
export const SET_UNLOCKED_ACHIEVEMENT_DATA = 'SET_UNLOCKED_ACHIEVEMENT_DATA';
export const ADD_UNLOCKED_ACHIEVEMENT_DATA = 'ADD_UNLOCKED_ACHIEVEMENT_DATA';

export const SET_PUPSWIPE_DATA = '@pupswipe/setData';
export const SET_PUPSWIPE_PAGE = '@pupswipe/setPage';
export const SET_PUPSWIPE_MORE = '@pupswipe/setMore';
export const SET_PUPSWIPE_STATUS = '@pupswipe/setStatus';
export const SET_PUPSWIPE_ERROR = '@pupswipe/setError';
export const SET_PUPSWIPE_TUTORIAL = '@pupswipe/setWithTutorial';
export const SET_PUPSWIPE_CARD_REMOVE = '@pupswipe/setCardRemove';
export const SET_WHEEL_DATA = '@pupswipe/setWheelData';
export const SET_IS_BLOCKED = '@pupswipe/setIsBlocked';
export const SET_PROGRESS = '@pupswipe/setProgress';
export const SET_TOTAL_CARDS = '@pupswipe/setTotalCards';
export const SET_GAME_ENABLED = '@pupswipe/setGameEnabled';
export const SET_PUPSWIPES_COUNT = '@pupswipe/setCount';
export const RESET_PUPSWIPE_WHEEL = '@pupswipe/resetWheel';
export const SET_PUPSWIPE_MODAL = '@pupswipe/setModalState';
export const RESET_PUPSWIPE = '@pupswipe/reset';
export const SET_PUPSWIPE_INDICATORS = '@pupswipe/setIndicators';
export const SET_PUPSWIPE_BLOCKED = '@pupswipe/setIsBlocked';
export const SET_PUPSWIPE_TIMELEFT = '@pupswipe/setTimeLeft';
export const SET_PUPSWIPE_ENABLED = '@app/setPupswipeEnabled';

export const SET_PET_AWARDS = '@pet/setPetAwards';
export const SET_PET_PHOTOS = '@pet/setPetPhotos';
export const SET_ALL_PET_PHOTOS = '@pet/setAllPetPhotos';

export const UPDATE_MY_PETS_STATUS = '@myPets/updateMyPetStatus';
export const SET_COUNTRY_MODAL_DISABLED = '@app/countryModalDisabled';
export const SET_WALLET_COLLAPSED = '@app/setWalletCollapsed';

export const UPDATE_USER = '@user/updateUser';

export const SET_TOURNAMENTS_FEATURE_MODAL = '@app/tournamentsFeatureModal';

export const showedTournamentVideoPopup = '@app/ShowedTournamentVideoPopup';

export const showCustomNotification = '@app/showCustomNotification';

export const showedReferralCongratulationsPopup = '@app/ShowedReferralCongratulationsPopup';

export const SET_LOADING_CURRENCY_EXCHANGE = '@app/SET_LOADING_CURRENCY_EXCHANGE';
export const SET_DATA_CURRENCY_EXCHANGE = '@app/SET_DATA_CURRENCY_EXCHANGE';
export const SET_ERROR_CURRENCY_EXCHANGE = '@app/SET_ERROR_CURRENCY_EXCHANGE';

export const SET_STREAK_NOTIFICATIONS_OPENED = '@streaks/SET_STREAK_NOTIFICATIONS_OPENED';
export const ADD_STREAK_NOTIFICATION_TO_LIST = '@streaks/ADD_STREAK_NOTIFICATION_TO_LIST';
export const REMOVE_TOP_STREAK_NOTIFICATION_FROM_LIST = '@streaks/REMOVE_TOP_STREAK_NOTIFICATION_FROM_LIST';
export const SET_STREAK_COMPLETE_ALERT_OPENED = '@streaks/SET_STREAK_COMPLETE_ALERT_OPENED';
export const SET_STREAK_COMPLETE_ALERT_DATA = '@streaks/SET_STREAK_COMPLETE_ALERT_DATA';
