import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import loadable from '@loadable/component';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const styles = {
  icon: {
    width: 24,
    height: 24,
    padding: 9,
    '&.active': {
      cursor: 'pointer',
    },
  },
  primary: {
    fill: 'var(--color-primary)',
  },
  secondary: {
    fill: 'var(--color-secondary)',
  },
  black: {
    fill: 'rgba(0, 0, 0, 0.32)',
    '&.active.no-touch:hover': {
      fill: 'var(--color-text)',
    },
  },
  arrow: {
    fill: 'var(--color-secondary)',
    '&.active.no-touch:hover': {
      fill: '#fff',
    },
  },
};
const AddCircle = loadable(() => import('@material-ui/icons/AddCircle'));
const Add = loadable(() => import('@material-ui/icons/Add'));
const RemoveCircle = loadable(() => import('@material-ui/icons/RemoveCircle'));
const Favorite = loadable(() => import('@material-ui/icons/Favorite'));
const ArrowForwardIos = loadable(() => import('@material-ui/icons/ArrowForwardIos'));
const ArrowBackIos = loadable(() => import('@material-ui/icons/ArrowBackIos'));
const Delete = loadable(() => import('@material-ui/icons/Delete'));
const Create = loadable(() => import('@material-ui/icons/Create'));
const Menu = loadable(() => import('@material-ui/icons/Menu'));
const Search = loadable(() => import('@material-ui/icons/Search'));
const AccountCircle = loadable(() => import('@material-ui/icons/AccountCircle'));
const Home = loadable(() => import('@material-ui/icons/Home'));
const Stars = loadable(() => import('@material-ui/icons/Stars'));
const Bookmark = loadable(() => import('@material-ui/icons/Bookmark'));
const Assignment = loadable(() => import('@material-ui/icons/Assignment'));
const QuestionAnswer = loadable(() => import('@material-ui/icons/QuestionAnswer'));
const ContactSupport = loadable(() => import('@material-ui/icons/ContactSupport'));
const ExitToApp = loadable(() => import('@material-ui/icons/ExitToApp'));
const Pets = loadable(() => import('@material-ui/icons/Pets'));
const Block = loadable(() => import('@material-ui/icons/Block'));
const Adb = loadable(() => import('@material-ui/icons/Adb'));
const Email = loadable(() => import('@material-ui/icons/Email'));
const Call = loadable(() => import('@material-ui/icons/Call'));
const Share = loadable(() => import('@material-ui/icons/Share'));
const Flag = loadable(() => import('@material-ui/icons/Flag'));
const Events = loadable(() => import('@material-ui/icons/Event'));
const Comment = loadable(() => import('@material-ui/icons/Comment'));
const InsertLink = loadable(() => import('@material-ui/icons/InsertLink'));
const Style = loadable(() => import('@material-ui/icons/Style'));
const KeyboardArrowLeft = loadable(() => import('@material-ui/icons/KeyboardArrowLeft'));
const KeyboardArrowRight = loadable(() => import('@material-ui/icons/KeyboardArrowRight'));

const icons = {
  AddCircle,
  Add,
  RemoveCircle,
  Favorite,
  ArrowForwardIos,
  ArrowBackIos,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Delete,
  Create,
  Menu,
  Search,
  AccountCircle,
  Home,
  Stars,
  Bookmark,
  Assignment,
  QuestionAnswer,
  ContactSupport,
  ExitToApp,
  Pets,
  Block,
  Adb,
  Email,
  Call,
  Share,
  Flag,
  Comment,
  InsertLink,
  Style,
  Events,
};

const CustomIcon = (props) => {
  const {
    name, type, active, classes, className, device, onClick,
  } = props;
  const TagName = icons[name];
  const classnames = classNames(
    classes.icon,
    active,
    type === 'primary' ? classes.primary : null,
    type === 'secondary' ? classes.secondary : null,
    type === 'black' ? classes.black : null,
    !type ? classes.black : null,
    name === 'KeyboardArrowLeft' || name === 'KeyboardArrowRight'
      ? classes.arrow
      : null,
  );
  return (
    <React.Fragment>
      {TagName ? (
        <TagName
          className={classNames(classnames, className, device)}
          onClick={onClick}
        />
      ) : null}
    </React.Fragment>
  );
};

CustomIcon.propTypes = {
  active: PropTypes.string,
  className: PropTypes.any,
  classes: PropTypes.object.isRequired,
  device: PropTypes.any,
  name: PropTypes.string,
  onClick: PropTypes.any,
  type: PropTypes.string,
};

CustomIcon.defaultProps = {
  active: 'active',
  name: null,
  type: 'primary',
};

function mapStateToProps(state) {
  const { device } = state.app;
  return { device };
}

export default connect(mapStateToProps)(withStyles(styles)(CustomIcon));
