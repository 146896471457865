import { proxyHandler } from 'src/utils/redux';

const prefix = '@contest';

const constants = {
  setCurrentOrder: 'setCurrentOrder',
  setContestTop: 'setContestTop',
  setPerPage: 'setPerPage',
  setFirstLoad: 'setFirstLoad',
  setContestLoadingStatus: 'setContestLoadingStatus',
  setPetIsLoaded: 'setPetIsLoaded',
  setIsLoaded: 'setIsLoaded',
  setStatus: 'setStatus',
  setPage: 'setPage',
  reset: 'reset',
  softReset: 'softReset',
};

export const CONTEST_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
