import {
  SET_TESTIMONIALS_DATA,
  SET_TESTIMONIALS_IS_LOADED,
  SET_TESTIMONIALS_IS_LOADING,
  SET_TESTIMONIALS_META,
  SET_TESTIMONIALS_PAGE,
  RESET_TESTIMONIALS,
  SET_TESTIMONIALS_AMOUNT,
  SET_TESTIMONIALS_FIRST_LOAD,
} from '../actionTypes';

const initialState = {
  isLoaded: false,
  isLoading: true,
  data: [],
  meta: null,
  page: 1,
  amount: window.innerWidth > 672 ? 9 : 3,
  firstLoad: true,
};

const petsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TESTIMONIALS_FIRST_LOAD:
      return {
        ...state,
        firstLoad: action.payload,
      };
    case SET_TESTIMONIALS_DATA:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    case SET_TESTIMONIALS_META:
      return {
        ...state,
        meta: action.payload,
      };
    case SET_TESTIMONIALS_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_TESTIMONIALS_IS_LOADED:
      return {
        ...state,
        isLoaded: action.payload,
      };
    case SET_TESTIMONIALS_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_TESTIMONIALS_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case RESET_TESTIMONIALS:
      return {
        ...state,
        isLoading: true,
        data: [],
        meta: null,
        page: 1,
        amount: window.innerWidth > 672 ? 9 : 3,
      };
    default:
      return state;
  }
};

export default petsReducer;
