import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide } from '@material-ui/core';
import Button from 'src/components/Form/Button';
import { removeTopNotificationFromStreaksList, triggerSnackBar } from 'src/actions/app';
import { defineMessages, useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory, useLocation } from 'react-router-dom';
import s from './StreakNotifications.module.scss';
import { patchUsersMeStreaksById } from '../../api/streaks';
import Popup from '../../../../components/Popup';
import { fetchMyStreaks, fetchStats, resetPupstreaks } from '../../actions/streaks';

const messages = defineMessages({
  streakAccepted: {
    id: 'streaks.inbox.accepted',
    defaultMessage: 'A new streak has started',
  },
  rejectInviteSuccess: {
    id: 'streaks.inbox.rejectInviteSuccess',
    defaultMessage: 'Invite successfully rejected',
  },
  error: {
    id: 'streaks.error',
    defaultMessage: 'Something went wrong. Please try again later',
  },
  hey: {
    id: 'streaks.alert.hello',
    defaultMessage: 'Hey {nickname} 🔥',
  },
  receivedFrom: {
    id: 'streaks.alert.receivedFrom',
    defaultMessage: 'You have received an invite from {from} to become streak buddies. You would have to vote for each other`s pet every day and can earn up to thousand of bonus votes.',
  },
  cta: {
    id: 'streaks.alert.start',
    defaultMessage: 'Start the streak today and start earning votes',
  },
  accept: {
    id: 'streaks.accept',
    defaultMessage: 'Accept',
  },
  reject: {
    id: 'streaks.reject',
    defaultMessage: 'Reject',
  },
  maxNumber: {
    id: 'streaks.maxNumber',
    defaultMessage: 'You have reached the maximum number of streaks. You need to leave one of them to accept this one',
  },
});

const StreakNotifications = () => {
  const streakNotifications = useSelector(state => state.app.streakNotifications);
  const code = useSelector((state) => state.app.currentCountry.code.toLowerCase());

  const history = useHistory();

  const dispatch = useDispatch();

  const isMobile = useMediaQuery('@media (max-width: 650px)');

  const { formatMessage } = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isAnimation, setIsAnimation] = useState(false);

  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isAnimation && streakNotifications.list?.length > 0 && !isClosing) {
      setIsAnimation(true);
    }
  }, [streakNotifications, isAnimation, isClosing]);

  if (streakNotifications.list.length === 0) {
    return null;
  }

  const location = useLocation();

  const activeItem = streakNotifications.list[0];

  const updateStatus = async (status) => {
    try {
      setIsSubmitting(true);
      const res = await patchUsersMeStreaksById(activeItem.id, status);

      if (res.ok) {
        if (status === 'accept') {
          history.push(`/${code}/streaks/pupstreaks`);
        }

        if (status === 'accept' && location.pathname.includes('streaks/pupstreaks')) {
          dispatch(resetPupstreaks());
          dispatch(fetchStats());
          dispatch(fetchMyStreaks());
        }

        dispatch(triggerSnackBar(true, status === 'accept' ? formatMessage(messages.streakAccepted) : formatMessage(messages.rejectInviteSuccess)));
      } else if (res.status === 409) {
        dispatch(triggerSnackBar(true, formatMessage(messages.maxNumber)));
      } else {
        const response = await res.json();
        dispatch(triggerSnackBar(true, response.message || formatMessage(messages.error)));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsClosing(true);
      setIsSubmitting(false);

      if (isMobile) {
        dispatch(removeTopNotificationFromStreaksList());

        setTimeout(() => {
          setIsClosing(false);
        }, 300);
      }
    }
  };

  const content = (
    <div className={s.wrapper}>
      {!isMobile && (
        <button
          type="button"
          className={s.close}
          onClick={() => {
            setIsClosing(true);
          }}
          disabled={isSubmitting}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" fill="black" fillOpacity="0.32" />
          </svg>
        </button>
      )}
      <div className={s.content}>
        <div className={s.images}>
          <img src={`${activeItem?.creator_post_image}_480x480.jpg`} alt="" />
          <img src={`${activeItem?.paired_post_image}_480x480.jpg`} alt="" />
        </div>
        <div className={s.notification}>
          <p className={s.title}>
            {/* {formatMessage(messages.hey)} */}
            {activeItem?.title}
          </p>
          <p className={s.text}>
            {/* {formatMessage(messages.receivedFrom, { from: 'Nick' })} */}
            {activeItem?.description}
          </p>
          <p className={s.cta}>
            {/* {formatMessage(messages.cta)} */}
            {activeItem?.footerText}
          </p>
          <div className={s.buttons}>
            {
              isMobile ? (
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    updateStatus('reject');
                  }}
                >
                  {formatMessage(messages.reject)}
                </Button>
              ) : (
                <button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    updateStatus('reject');
                  }}
                >
                  {formatMessage(messages.reject)}
                </button>
              )
            }
            <Button
              className="primary"
              disabled={isSubmitting}
              onClick={() => {
                updateStatus('accept');
              }}
            >
              {formatMessage(messages.accept)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <Popup
        closeBtn
        closeOnEsc={false}
        open={activeItem && !isClosing}
        closeOnOverlay={false}
        paperClassname={s.popupWrapper}
        onClose={() => {
          setIsClosing(true);
          dispatch(removeTopNotificationFromStreaksList());

          setTimeout(() => {
            setIsClosing(false);
          }, 150);
        }}
      >
        {content}
      </Popup>
    );
  }

  return (
    <Slide
      in={isClosing ? false : isAnimation}
      direction="left"
      onExited={() => {
        dispatch(removeTopNotificationFromStreaksList());
        setTimeout(() => {
          setIsClosing(false);
        }, 150);
      }}
    >
      {content}
    </Slide>
  );
};

export default StreakNotifications;
