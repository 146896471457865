import { produce } from 'immer';
import CHALLENGE_CONSTANTS from 'src/modules/challenges/constants/challenge';

const initialState = {
  info: {
    data: null,
    status: 'LOADING',
    error: null,
  },
  leaderboard: {
    data: [],
    pageToLoad: 0,
    status: 'LOADING',
    isMore: true,
    error: null,
    isLoaded: false,
    scrollingToItem: false,
    replacePlaceholderInfo: {
      isLoading: false,
      loadingGroup: null,
    },
  },
  photo: {
    data: null,
    status: 'LOADING',
    error: null,
  },
  packages: {
    data: [],
    status: 'LOADING',
    error: null,
  },
  voteInfo: {
    postId: null,
    photoId: null,
    petName: null,
    petImage: null,
    votesCount: null,
    place: null,
  },
  myPhotos: {
    data: null,
    status: 'LOADING',
    error: null,
  },
};

const challengeReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case CHALLENGE_CONSTANTS.SET_REPLACE_PLACEHOLDER_INFO:
      draft.leaderboard.replacePlaceholderInfo = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_SCROLLING_ITEM:
      draft.leaderboard.scrollingToItem = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_INFO_DATA:
      draft.info.data = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_INFO_STATUS:
      draft.info.status = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_INFO_ERROR:
      draft.info.error = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_LEADERBOARD_DATA:
      draft.leaderboard.data = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_LEADERBOARD_ERROR:
      draft.leaderboard.error = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_LEADERBOARD_IS_LOADED:
      draft.leaderboard.isLoaded = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_LEADERBOARD_IS_MORE:
      draft.leaderboard.isMore = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_LEADERBOARD_PAGE:
      draft.leaderboard.pageToLoad = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_LEADERBOARD_STATUS:
      draft.leaderboard.status = action.payload;
      break;
    case CHALLENGE_CONSTANTS.UPDATE_CHALLENGE_LEADERBOARD_ITEM:
      const newLeaderboard = state.leaderboard.data
        .map((item) => {
          if (
            item.id === action.payload.photoId
            && item.post.id === action.payload.postId
          ) {
            return {
              ...item,
              votes: action.payload.votesNumber,
            };
          }
          return item;
        })
        .sort((a, b) => b.votes - a.votes)
        .map((item, index) => ({
          ...item,
          place: index + 1,
        }));
      state.leaderboard.data = newLeaderboard;
      break;
    case CHALLENGE_CONSTANTS.RESET_CHALLENGE_PAGE:
      return initialState;
    case CHALLENGE_CONSTANTS.RESET_CHALLENGE_LEADERBOARD:
      draft.leaderboard = initialState.leaderboard;
      return draft;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_PHOTO_DATA:
      draft.photo.data = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_PHOTO_STATUS:
      draft.photo.status = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_PHOTO_ERROR:
      draft.photo.error = action.payload;
      break;
    case CHALLENGE_CONSTANTS.RESET_CHALLENGE_PHOTO:
      draft.photo = initialState.photo;
      return draft;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_PACKAGES_DATA:
      draft.packages.data = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_PACKAGES_STATUS:
      draft.packages.status = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_PACKAGES_ERROR:
      draft.packages.error = action.payload;
      break;
    case CHALLENGE_CONSTANTS.RESET_CHALLENGE_PACKAGES:
      draft.packages = initialState.packages;
      return draft;
    case CHALLENGE_CONSTANTS.SET_CHALLENGE_VOTE_INFO:
      draft.voteInfo = {
        ...state.voteInfo,
        ...action.payload,
      };
      break;
    case CHALLENGE_CONSTANTS.RESET_CHALLENGE_VOTE_INFO:
      draft.voteInfo = initialState.voteInfo;
      return draft;
    case CHALLENGE_CONSTANTS.SET_MY_PHOTOS_DATA:
      draft.myPhotos.data = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_MY_PHOTOS_STATUS:
      draft.myPhotos.status = action.payload;
      break;
    case CHALLENGE_CONSTANTS.SET_MY_PHOTOS_ERROR:
      draft.myPhotos.error = action.payload;
      break;
    default:
      return state;
  }
});

export default challengeReducer;
