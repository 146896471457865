import { proxyHandler } from 'src/utils/redux';

const prefix = '@petPhotos';

const constants = {
  SET_PETS_PHOTOS_STATUS: 'setPetsPhotosStatus',
  SET_PETS_PHOTOS_IS_LOADED: 'setPetsPhotosIsLoaded',
  SET_PETS_PHOTOS_PAGE: 'setPetsPhotosPage',
  SET_PETS_PHOTOS_DATA: 'setPetsPhotosData',
  SET_PETS_PHOTOS_ERROR: 'setPetsPhotosError',
  SET_PETS_IS_MORE: 'setPetsPhotosIsMore',
  SET_PETS_PHOTOS_META: 'setPetsPhotosMeta',
  RESET_PETS_PHOTOS: 'reset',
};

const PETS_PHOTOS_CONSTANTS = new Proxy(constants, proxyHandler(prefix));

export default PETS_PHOTOS_CONSTANTS;
