import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowCustomNotification } from 'src/actions/app';
import { debounce } from 'src/utils';
import Button from 'src/components/Form/Button';
import { getTournamentNotification } from 'src/modules/tournaments/api/tournament';
import { useHistory } from 'react-router-dom';
import { countryCodeSelector } from 'src/modules/common/selectors/global';
import { defineMessages, injectIntl } from 'react-intl';
import { userSelector } from 'src/modules/common/selectors/user';
import s from './TournamentNotifications.module.scss';

const TournamentNotifications = ({ intl: { formatMessage } }) => {
  const [notificationData, setNotificationData] = useState(null);

  const user = useSelector(userSelector);
  const isNotification = useSelector(({ app }) => app.showCustomNotification);
  const code = useSelector(countryCodeSelector);

  const notificationRef = useRef();

  const dispatch = useDispatch();

  const history = useHistory();

  const showNotification = debounce(() => {
    dispatch(setShowCustomNotification(true));
    window.gsap.fromTo(notificationRef.current, { x: 458 }, { x: 0 });
  }, 5000);

  useEffect(() => {
    if (user && user.login && !user.is_showed_tournament_push && !isNotification) {
      getTournamentNotification().then(res => res.json()).then((result) => {
        if (result.status) {
          showNotification();
          setNotificationData(result);
        }
      });
    }
  }, [user.login, user.is_showed_tournament_push]);

  const handleCloseNotification = () => {
    window.gsap.fromTo(notificationRef.current, { x: 0 }, { x: 700 });
    setTimeout(() => {
      dispatch(setShowCustomNotification(false));
    }, 1000);
  };

  return (
    <div className={s.wrapper}>
      {
        isNotification && (
          <div
            ref={notificationRef}
            className={s.notification}
          >
            <button
              type="button"
              onClick={handleCloseNotification}
            >
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.893.302a.996.996 0 00-1.41 0l-4.89 4.88-4.89-4.89a.996.996 0 10-1.41 1.41l4.89 4.89-4.89 4.89a.996.996 0 101.41 1.41l4.89-4.89 4.89 4.89a.996.996 0 101.41-1.41l-4.89-4.89 4.89-4.89c.38-.38.38-1.02 0-1.4z" />
              </svg>
            </button>
            <div className={s.image}>
              <img src={`${notificationData.participant.image}_96x96.jpg`} alt="" />
            </div>
            <div className={s.content}>
              <span className={s.userCall}>
                {formatMessage(messages['Hey {name}'], { name: user.nickname })}
                {' '}
                👋
              </span>
              <p>
                {
                  formatMessage(messages['A tournament that is perfect for {petName} is about to go live! Check it out now'], { petName: notificationData.participant.name })
                }
                :
              </p>
              <div className={s.tournamentInfo}>
                <span>{notificationData.tournament.event}</span>
                <div className={s.participantsInfo}>
                  <i>
                    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M22.37 18.55c1.868 1.279 3.177 3.011 3.177 5.321v4.125h4.091c.75 0 1.364-.618 1.364-1.375v-2.75c0-2.997-4.868-4.771-8.632-5.321z" fill="var(--color-neutral-3)" />
                        <path d="M11.905 17c3.012 0 5.454-2.462 5.454-5.5S14.917 6 11.905 6C8.892 6 6.45 8.462 6.45 11.5S8.892 17 11.905 17z" fill="var(--color-neutral-3)" />
                        <g><path d="M20.094 17c3.013 0 5.454-2.461 5.454-5.5S23.108 6 20.094 6c-.641 0-1.241.138-1.814.33a8.265 8.265 0 011.814 5.17 8.265 8.265 0 01-1.814 5.17c.573.193 1.173.33 1.814.33z" fill="var(--color-neutral-3)" /></g>
                        <g><path d="M11.91 18.38C8.267 18.38 1 20.223 1 23.88v2.75c0 .756.614 1.375 1.364 1.375h19.09c.75 0 1.364-.619 1.364-1.375v-2.75c0-3.658-7.268-5.5-10.909-5.5z" fill="var(--color-neutral-3)" /></g>
                      </g>
                    </svg>
                  </i>
                  <div className={s.spaceLeftInfo}>
                    <span>
                      {notificationData.tournament.current_participant_count}
                      {' '}
                      /
                      {' '}
                      {notificationData.tournament.participant_count}
                    </span>
                    <span>
                      {notificationData.tournament.participant_count - notificationData.tournament.current_participant_count}
                      {' '}
                      {formatMessage(messages['spots left'])}
                    </span>
                  </div>
                </div>
              </div>
              <Button
                className="primary"
                onClick={() => {
                  handleCloseNotification();
                  history.push(`/${code}/tournaments/all_levels/${notificationData.tournament.id}`);
                }}
              >
                {formatMessage(messages['Play now'])}
              </Button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default injectIntl(TournamentNotifications);

const messages = defineMessages({
  'Hey {name}': {
    id: 'Hey {name}',
    defaultMessage: 'Hey {name}',
  },
  'A tournament that is perfect for {petName} is about to go live! Check it out now': {
    id: 'A tournament that is perfect for {petName} is about to go live! Check it out now',
    defaultMessage: 'A tournament that is perfect for {petName} is about to go live! Check it out now',
  },
  'Play now': {
    id: 'Play now',
    defaultMessage: 'Play now',
  },
  'spots left': {
    id: 'spots left',
    defaultMessage: 'spots left',
  },
});
