import {
  SET_PET_EDIT,
  SET_ADD_PET_CARD_INFO,
  SET_PET_AWARDS,
  SET_PET_PHOTOS,
  SET_ALL_PET_PHOTOS,
} from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  petIsEditing: false,
  pet_name: '',
  pet_photo: '',
  croppedAreaPixels: {},
  imgSrc: '',
  awards: {
    data: [],
    status: 'LOADING',
    error: null,
  },
  photos: {
    data: [],
    meta: {},
    status: 'LOADING',
    error: null,
  },
  allPhotos: {
    data: [],
    meta: {},
    status: 'LOADING',
    error: null,
  },
};

const petsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PET_EDIT:
      return newState(state, {
        petIsEditing: action.payload,
      });
    case SET_ADD_PET_CARD_INFO:
      return newState(state, {
        pet_name: action.payload.name,
        pet_photo: action.payload.photo,
        imgSrc: action.payload.imgSrc,
        croppedAreaPixels: action.payload.croppedAreaPixels,
      });
    case SET_PET_AWARDS:
      return newState(state, {
        awards: {
          ...state.awards,
          ...action.payload,
        },
      });
    case SET_PET_PHOTOS:
      return newState(state, {
        photos: {
          ...state.photos,
          ...action.payload,
        },
      });
    case SET_ALL_PET_PHOTOS:
      return newState(state, {
        allPhotos: {
          ...action.payload,
        },
      });
    default:
      return state;
  }
};

export default petsReducer;
