import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { enableScroll } from '../../utils';

const styles = (theme) => ({
  paper: {
    margin: '0',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 2,
    '&.achievements': {
      maxWidth: '550px',
      width: '100%',
      margin: '0 15px',
    },
    '&.preloader-popup': {
      background: 'transparent',
      boxShadow: 'none',
    },
    '&.vote-paper>div:nth-child(2)': {
      '@media(min-width: 768px)': {
        height: 'auto!important',
      },
    },
    '&.share-ref-paper>div:nth-child(2)': {
      height: 'auto!important',
    },
    '&.share-ref-paper': {
      marginLeft: 16,
      marginRight: 16,
    },
    '&.vote-paper .closeBtn-modal': {
      padding: '12px',
      top: '0',
      right: '0',
    },
    '&.photoPreview': {
      height: '100%',
      width: '100%',
      '@media (max-width: 876px)': {
        background: '#fff',
      },
    },
    '&.choosePhoto': {
      '@media (max-width: 560px)': {
        height: '100%',
        width: '100%',
      },
    },
    '&.payToParticipate': {
      '@media (max-width: 580px)': {
        height: '100%',
        width: '100%',
      },
    },
    '&.tournamentsFeature': {
      overflow: 'visible',
      maxWidth: 'calc(100% - 32px)',
      '@media (max-width: 1024px)': {
        'overflow-y': 'auto',
      },
      '@media (max-width: 750px)': {
        width: '100%',
      },
    },
  },
});

class Popup extends PureComponent {
  targetElement = null;

  static defaultProps = {
    position: 'topCenter',
    closeOnOverlay: true,
    overlayStyle: {},
  };

  static propTypes = {
    open: PropTypes.bool,
    closeBtn: PropTypes.bool,
    closeOnOverlay: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    onClose: PropTypes.func,
    wrapperClassname: PropTypes.string,
    overlayStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    position: PropTypes.oneOf([
      'topLeft',
      'topCenter',
      'topRight',
      'centerLeft',
      'centerCenter',
      'centerRight',
      'bottomLeft',
      'bottomCenter',
      'bottomRight',
    ]),
  };

  componentDidMount() {
    this.targetElement = document.querySelector(this.props.wrapperClassname);
    if (document.querySelector('.modal-wrapper')) {
      disableBodyScroll(document.querySelector('.modal-wrapper'));
    }
  }

  componentWillUnmount() {
    if (!this.props.disableBody) {
      clearAllBodyScrollLocks();
      enableScroll();
    }
    clearAllBodyScrollLocks();
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleEscKeyDown);
    }
  }

  handleCloseBtn = () => {
    clearAllBodyScrollLocks();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleEscKeyDown = (e) => {
    if (this.props.onClose && this.props.closeOnEsc && e.keyCode === 27) {
      clearAllBodyScrollLocks();
      enableScroll();
      this.props.onClose();
    }
  };

  onClose = (event, reason) => {
    clearAllBodyScrollLocks();
    if (reason === 'backdropClick' && this.props.closeOnOverlay) {
      this.props.onClose();
    }
    if (reason === 'escapeKeyDown' && this.props.closeOnEsc) {
      this.props.onClose();
    }
  };

  render() {
    const {
      open,
      classes,
      achievements,
      photoPreview,
      choosePhoto,
      className,
      tournamentsFeature,
    } = this.props;
    if (!open) return null;
    return (
      <Dialog
        onClose={this.onClose}
        aria-labelledby="simple-dialog-title"
        classes={{
          root: this.props.className,
          paper: classNames(classes.paper, this.props.paperClassname, {
            'preloader-popup': this.props.preloader,
            achievements,
            photoPreview,
            choosePhoto,
            tournamentsFeature,
          }),
        }}
        open={open}
        className={className}
      >
        {this._renderCloseBtn()}
        {this.props.children}
      </Dialog>
    );
  }

  _renderCloseBtn() {
    if (this.props.closeBtn) {
      return (
        <div onClick={this.handleCloseBtn} className="closeBtn-modal">
          <svg
            className="MuiSvgIcon-root-35 searchInput_icon__2Rm1I"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path fill="none" d="M0 0h24v24H0z" />
          </svg>
        </div>
      );
    }
    return null;
  }
}

export default withStyles(styles)(Popup);
