import { proxyHandler } from 'src/utils/redux';

const prefix = '@streaks';

const constants = {
  setPupstreaksData: 'setPupstreaksData',
  resetPupstreaks: 'resetPupstreaks',
  setPupstreaksError: 'setPupstreaksError',
  setPupstreaksStatus: 'setPupstreaksStatus',
  filterPupstreakItemById: 'filterPupstreakItemById',
  setPairedPetVoted: 'setPairedPetVoted',
  setPupstreakReminded: 'setPupstreakReminded',
  incrementCompletedDays: 'incrementPupstreakCompletedDays',

  setHistoryData: 'setHistoryData',
  resetHistory: 'resetHistory',
  setHistoryError: 'setHistoryError',
  setHistoryStatus: 'setHistoryStatus',
  setHistoryFirstLoad: 'setHistoryFirstLoad',
  setHistoryIsMore: 'seHistoryIsMore',
  setHistoryPage: 'seHistoryPage',
  filterHistoryItemById: 'filterHistoryItemById',

  setSentData: 'setSentData',
  resetSent: 'resetSent',
  setSentError: 'setSentError',
  setSentStatus: 'setSentStatus',
  filterSentItemById: 'filterSentItemById',

  setInboxData: 'setInboxData',
  resetInbox: 'resetInbox',
  setInboxError: 'setInboxError',
  setInboxStatus: 'setInboxStatus',
  setInboxFirstLoad: 'setInboxFirstLoad',
  setInboxIsMore: 'seInboxIsMore',
  setInboxPage: 'seInboxPage',
  filterInboxItemById: 'filterInboxItemById',

  setStatsData: 'setStatsData',
  resetStats: 'resetStats',
  setStatsError: 'setStatsError',
  setStatsStatus: 'setStatsStatus',

  setLeaveStreakOpenedId: 'setLeaveStreakOpenedId',
  setCancelInviteOpenedId: 'setCancelInviteOpenedId',
  setRejectInviteOpenedId: 'setRejectInviteOpenedId',
  setHistoryRestoreOpenedId: 'setHistoryRestoreOpenedId',
};

export const STREAKS_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
