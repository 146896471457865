import { combineReducers } from 'redux';
import paymentsHistory from 'src/reducers/paymentsHistory';
import withdraw from 'src/reducers/withdraw';
import tournaments from 'src/modules/tournaments/reducers/tournaments';
import tournamentGame from 'src/modules/tournaments/reducers/tournament-game';
import leaderboard from 'src/modules/contest/reducers/leaderboard';
import contest from 'src/modules/contest/reducers/contest';
import myPets from 'src/modules/my-pets/reducers/my-pets';
import challenges from 'src/modules/challenges/reducers/challenges';
import challenge from 'src/modules/challenges/reducers/challenge';
import petsPhotos from 'src/modules/photos/reducers/pets-photos';
import streaks from 'src/modules/streaks/reducers/streaks';
import pets from './pets';
import app from './app';
import pet from './pet';
import search from './search';
import user from './user';
import referral from './referral';
import registration from './registration';
import comments from './comments';
import testimonials from './testimonials';
import achievements from './achievements';
import pupswipe from './pupswipe';
import deposit from './deposit';

export default combineReducers({
  pets,
  app,
  search,
  user,
  registration,
  comments,
  pet,
  testimonials,
  achievements,
  pupswipe,
  petsPhotos,
  challenges,
  challenge,
  deposit,
  paymentsHistory,
  withdraw,
  tournaments,
  tournamentGame,
  leaderboard,
  contest,
  myPets,
  referral,
  streaks,
});
