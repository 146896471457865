import { proxyHandler } from 'src/utils/redux';

const prefix = '@myPets';

const constants = {
  setMyPets: 'setMyPets',
  resetMyPets: 'resetMyPets',
  setPage: 'setPage',
  setStatus: 'setStatus',
  setFirstLoad: 'setFirstLoad',
  setIsMore: 'setIsMore',
};

export const MY_PETS_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
