import { STREAKS_CONSTANTS } from '../constants/streaks';
import { LOADING_STATES } from '../../../utils/maps';
import {
  deleteUsersMeStreaksById,
  getUsersMeStreaks, getUsersMeStreaksHistory, getUsersMeStreaksInbox,
  getUsersMeStreaksSent, getUsersMeStreaksStats,
  patchUsersMeStreaksById,
} from '../api/streaks';
import { triggerPreloadPopup, triggerSnackBar } from '../../../actions/app';

/**
 *
 * @param payload {any[]}
 * @returns {{payload, type: string}}
 */
export const setPupstreaksData = (payload) => ({
  type: STREAKS_CONSTANTS.setPupstreaksData,
  payload,
});

/**
 *
 * @param payload {any}
 * @returns {{payload, type: string}}
 */
export const setPupstreaksError = (payload) => ({
  type: STREAKS_CONSTANTS.setPupstreaksError,
  payload,
});

/**
 *
 * @param payload {string}
 * @returns {{payload, type: string}}
 */
export const setPupstreaksStatus = (payload) => ({
  type: STREAKS_CONSTANTS.setPupstreaksStatus,
  payload,
});

export const resetPupstreaks = () => ({
  type: STREAKS_CONSTANTS.resetPupstreaks,
});

/**
 *
 * @param payload {number}
 * @returns {{payload, type: string}}
 */
export const filterPupstreakItemById = (payload) => ({
  type: STREAKS_CONSTANTS.filterPupstreakItemById,
  payload,
});

/**
 *
 * @param payload {number | null}
 * @returns {{payload, type: string}}
 */
export const setLeaveStreakOpenedId = (payload) => ({
  type: STREAKS_CONSTANTS.setLeaveStreakOpenedId,
  payload,
});

/**
 *
 * @returns {function(*): Promise<{response: *, status: number}>}
 */
export const fetchMyStreaks = () => (dispatch) => {
  let status = 0;

  dispatch(setPupstreaksStatus(LOADING_STATES.LOADING));

  return getUsersMeStreaks().then(r => {
    status = r.status;
    return r.json();
  }).then(response => {
    if (status !== 200) {
      dispatch(setPupstreaksError(response));
      dispatch(setPupstreaksStatus(LOADING_STATES.ERROR));
    } else {
      dispatch(setPupstreaksData(response.data));
      dispatch(setPupstreaksError(null));
      dispatch(setPupstreaksStatus(LOADING_STATES.LOADED));
    }

    return {
      status,
      response,
    };
  }).catch(err => {
    console.log(err);
    dispatch(setPupstreaksError(err));
    dispatch(setPupstreaksStatus(LOADING_STATES.ERROR));
  });
};

/**
 *
 * @param id {number}
 * @returns {function(*, *): Promise<{result: any, status: number}>}
 */
export const leaveStreak = (id) => (dispatch, getState) => {
  let status = 0;

  const state = getState();

  return deleteUsersMeStreaksById(id).then(r => {
    status = r.status;
    return r;
  }).then(result => {
    if (status === 204) {
      dispatch(filterPupstreakItemById(id));
      dispatch(triggerPreloadPopup(false));

      dispatch(setStatsData({
        ...state.streaks.stats.data,
        active: state.streaks.stats.data.active - 1,
      }));
    } else {
      dispatch(triggerPreloadPopup(false));
      dispatch(triggerSnackBar(true, 'Something went wrong'));
    }

    return {
      result,
      status,
    };
  }).catch(err => {
    console.log(err);
    dispatch(triggerPreloadPopup(false));
    dispatch(triggerSnackBar(true, 'Something went wrong'));
  });
};

/**
 *
 * @param payload {object}
 * @param payload.id {number}
 * @param payload.isVoted {boolean}
 * @returns {{payload, type: string}}
 */
export const setPairedPetVoted = (payload) => ({
  type: STREAKS_CONSTANTS.setPairedPetVoted,
  payload,
});

/**
 *
 * @param payload {object}
 * @param payload.id {number}
 * @param payload.reminded {boolean}
 * @returns {{payload, type: string}}
 */
export const setPupstreakReminded = (payload) => ({
  type: STREAKS_CONSTANTS.setPupstreakReminded,
  payload,
});

/**
 *
 * @param payload {any[]}
 * @returns {{payload, type: string}}
 */
export const setStreaksSentData = (payload) => ({
  type: STREAKS_CONSTANTS.setSentData,
  payload,
});

/**
 *
 * @param payload {any}
 * @returns {{payload, type: string}}
 */
export const setStreaksSentError = (payload) => ({
  type: STREAKS_CONSTANTS.setSentError,
  payload,
});

/**
 *
 * @param payload {string}
 * @returns {{payload, type: string}}
 */
export const setStreaksSentStatus = (payload) => ({
  type: STREAKS_CONSTANTS.setSentStatus,
  payload,
});

export const resetStreaksSentData = () => ({
  type: STREAKS_CONSTANTS.resetSent,
});

/**
 *
 * @param payload {number}
 * @returns {{payload, type: string}}
 */
export const filterSentItemById = (payload) => ({
  type: STREAKS_CONSTANTS.filterSentItemById,
  payload,
});

/**
 *
 * @returns {function(*): Promise<{response: any, status: number}>}
 */
export const fetchStreaksSent = () => (dispatch) => {
  let status = 0;

  dispatch(setStreaksSentStatus(LOADING_STATES.LOADING));

  return getUsersMeStreaksSent().then(r => {
    status = r.status;
    return r.json();
  }).then(response => {
    if (status !== 200) {
      dispatch(setStreaksSentError(response));
      dispatch(setStreaksSentStatus(LOADING_STATES.ERROR));
    } else {
      dispatch(setStreaksSentData(response.data));
      dispatch(setStreaksSentError(null));
      dispatch(setStreaksSentStatus(LOADING_STATES.LOADED));
    }

    return {
      status,
      response,
    };
  }).catch(err => {
    console.log(err);
    dispatch(setStreaksSentError(err));
    dispatch(setStreaksSentStatus(LOADING_STATES.ERROR));
  });
};

/**
 *
 * @param payload {number | null}
 * @returns {{payload, type: string}}
 */
export const setCancelInviteOpenedId = (payload) => ({
  type: STREAKS_CONSTANTS.setCancelInviteOpenedId,
  payload,
});

/**
 *
 * @param {number} id
 * @param {('accept'|'reject'|'cancel')} statusValue
 * @returns {function(*, *): Promise<{result: *, status: number}>}
 */
export const patchStreak = (id, statusValue) => (dispatch, getState) => {
  let status = 0;

  const state = getState();

  return patchUsersMeStreaksById(id, statusValue).then(r => {
    status = r.status;
    return r;
  }).then(result => {
    if (status === 204) {
      dispatch(triggerPreloadPopup(false));

      if (statusValue === 'accept' || statusValue === 'reject') {
        dispatch(filterInboxItemById(id));

        dispatch(setStatsData({
          ...state.streaks.stats.data,
          inbox: state.streaks.stats.data.inbox - 1,
        }));
      } else if (statusValue === 'cancel') {
        dispatch(filterSentItemById(id));
        dispatch(setStatsData({
          ...state.streaks.stats.data,
          sent: state.streaks.stats.data.sent - 1,
        }));
      }
    } else {
      dispatch(triggerPreloadPopup(false));
    }

    return {
      result,
      status,
    };
  }).catch(err => {
    console.log(err);
    dispatch(triggerPreloadPopup(false));
    dispatch(triggerSnackBar(true, 'Something went wrong'));
  });
};

/**
 *
 * @param payload {number | null}
 * @returns {{payload, type: string}}
 */
export const setRejectInviteOpenedId = (payload) => ({
  type: STREAKS_CONSTANTS.setRejectInviteOpenedId,
  payload,
});

/**
 *
 * @param payload {any[]}
 * @returns {{payload, type: string}}
 */
export const setInboxData = (payload) => ({
  type: STREAKS_CONSTANTS.setInboxData,
  payload,
});

/**
 *
 * @param payload {number}
 * @returns {{payload, type: string}}
 */
export const setInboxPage = (payload) => ({
  type: STREAKS_CONSTANTS.setInboxPage,
  payload,
});

/**
 *
 * @param payload {string}
 * @returns {{payload, type: string}}
 */
export const setInboxStatus = (payload) => ({
  type: STREAKS_CONSTANTS.setInboxStatus,
  payload,
});

/**
 *
 * @param payload {boolean}
 * @returns {{payload, type: string}}
 */
export const setInboxFirstLoad = (payload) => ({
  type: STREAKS_CONSTANTS.setInboxFirstLoad,
  payload,
});

/**
 *
 * @param payload {boolean}
 * @returns {{payload, type: string}}
 */
export const setInboxIsMore = (payload) => ({
  type: STREAKS_CONSTANTS.setInboxIsMore,
  payload,
});

/**
 *
 * @returns {{type: string}}
 */
export const resetInbox = () => ({
  type: STREAKS_CONSTANTS.resetInbox,
});

/**
 *
 * @param payload {number | null}
 * @returns {{payload, type: string}}
 */
export const filterInboxItemById = (payload) => ({
  type: STREAKS_CONSTANTS.filterInboxItemById,
  payload,
});

/**
 *
 * @returns {function(*, *): Promise<{response: any, status: number}>}
 */
export const fetchInbox = () => (dispatch, getState) => {
  let status = 0;

  const { streaks } = getState();
  dispatch(setInboxStatus(LOADING_STATES.LOADING));

  return getUsersMeStreaksInbox(5, streaks.inbox.page * 5).then(r => {
    status = r.status;
    return r.json();
  }).then(response => {
    if (streaks.inbox.firstLoad) {
      dispatch(setInboxFirstLoad(false));
    }

    dispatch(setInboxData(response.data));
    dispatch(setInboxPage(streaks.inbox.page + 1));
    dispatch(setInboxIsMore(Boolean(response.meta.nextLink)));
    dispatch(setInboxStatus(LOADING_STATES.LOADED));

    return {
      status,
      response,
    };
  }).catch(err => {
    console.log(err);
    dispatch(setInboxStatus(LOADING_STATES.ERROR));
  });
};

/**
 *
 * @param payload {any[]}
 * @returns {{payload, type: string}}
 */
export const setHistoryData = (payload) => ({
  type: STREAKS_CONSTANTS.setHistoryData,
  payload,
});

/**
 *
 * @param payload {number}
 * @returns {{payload, type: string}}
 */
export const setHistoryPage = (payload) => ({
  type: STREAKS_CONSTANTS.setHistoryPage,
  payload,
});

/**
 *
 * @param payload {string}
 * @returns {{payload, type: string}}
 */
export const setHistoryStatus = (payload) => ({
  type: STREAKS_CONSTANTS.setHistoryStatus,
  payload,
});

/**
 *
 * @param payload {boolean}
 * @returns {{payload, type: string}}
 */
export const setHistoryFirstLoad = (payload) => ({
  type: STREAKS_CONSTANTS.setHistoryFirstLoad,
  payload,
});

/**
 *
 * @param payload {boolean}
 * @returns {{payload, type: string}}
 */
export const setHistoryIsMore = (payload) => ({
  type: STREAKS_CONSTANTS.setHistoryIsMore,
  payload,
});

/**
 *
 * @returns {{type: string}}
 */
export const resetHistory = () => ({
  type: STREAKS_CONSTANTS.resetHistory,
});

/**
 *
 * @param payload {number | null}
 * @returns {{payload, type: string}}
 */
export const setHistoryRestoreOpenedId = (payload) => ({
  type: STREAKS_CONSTANTS.setHistoryRestoreOpenedId,
  payload,
});

/**
 *
 * @returns {function(*, *): Promise<{response: any, status: number}>}
 */
export const fetchHistory = () => (dispatch, getState) => {
  let status = 0;

  const { streaks } = getState();
  dispatch(setHistoryStatus(LOADING_STATES.LOADING));

  return getUsersMeStreaksHistory(5, streaks.history.page * 5).then(r => {
    status = r.status;
    return r.json();
  }).then(response => {
    if (streaks.history.firstLoad) {
      dispatch(setHistoryFirstLoad(false));
    }

    dispatch(setHistoryData(response.data));
    dispatch(setHistoryPage(streaks.history.page + 1));
    dispatch(setHistoryIsMore(Boolean(response.meta.nextLink)));
    dispatch(setHistoryStatus(LOADING_STATES.LOADED));

    return {
      status,
      response,
    };
  }).catch(err => {
    console.log(err);
    dispatch(setHistoryStatus(LOADING_STATES.ERROR));
  });
};

/**
 *
 * @param payload {number}
 * @returns {{}}
 */
export const filterHistoryItemsById = (payload) => ({
  type: STREAKS_CONSTANTS.filterHistoryItemById,
  payload,
});

/**
 *
 * @param payload {any}
 * @returns {{payload, type: string}}
 */
export const setStatsData = (payload) => ({
  type: STREAKS_CONSTANTS.setStatsData,
  payload,
});

/**
 *
 * @param payload {any}
 * @returns {{payload, type: string}}
 */
export const setStatsError = (payload) => ({
  type: STREAKS_CONSTANTS.setStatsError,
  payload,
});

/**
 *
 * @param payload {string}
 * @returns {{payload, type: string}}
 */
export const setStatsStatus = (payload) => ({
  type: STREAKS_CONSTANTS.setStatsStatus,
  payload,
});

/**
 *
 * @returns {{type: string}}
 */
export const resetStats = () => ({
  type: STREAKS_CONSTANTS.resetStats,
});

/**
 *
 * @returns {function(*): Promise<{response: any, status: number}>}
 */
export const fetchStats = () => (dispatch, getState) => {
  let status = 0;

  const { streaks } = getState();

  if (streaks.stats.status !== LOADING_STATES.LOADED) {
    dispatch(setStatsStatus(LOADING_STATES.LOADING));
  }

  return getUsersMeStreaksStats().then(r => {
    status = r.status;
    return r.json();
  }).then(response => {
    if (status !== 200) {
      dispatch(setStatsError(response));
      dispatch(setStatsStatus(LOADING_STATES.ERROR));
    } else {
      dispatch(setStatsData(response));
      dispatch(setStatsError(null));
      dispatch(setStatsStatus(LOADING_STATES.LOADED));
    }

    return {
      status,
      response,
    };
  }).catch(err => {
    console.log(err);
    dispatch(setStatsError(err));
    dispatch(setStatsStatus(LOADING_STATES.ERROR));
  });
};

export const incrementStreakCompletedDays = (payload) => ({
  type: STREAKS_CONSTANTS.incrementCompletedDays,
  payload,
});
