import { apiFetch, source } from '../../common/api';
import { getBearer } from '../../../utils/api';

/**
 * GET /users/me/streaks/active
 *
 * @returns {Promise<Response>}
 */
export const getUsersMeStreaks = () => apiFetch(`${source}/users/me/streaks/active`, {
  headers: {
    ...getBearer(),
  },
});

/**
 * DELETE /users/me/streaks/{streakId}
 *
 * @param {number} id
 * @returns {Promise<Response>}
 */
export const deleteUsersMeStreaksById = (id) => apiFetch(`${source}/users/me/streaks/${id}`, {
  method: 'DELETE',
  headers: {
    ...getBearer(),
  },
});

/**
 * GET /users/me/streaks/sent
 *
 * @returns {Promise<Response>}
 */
export const getUsersMeStreaksSent = () => apiFetch(`${source}/users/me/streaks/sent`, {
  headers: {
    ...getBearer(),
  },
});

/**
 * GET /users/me/streaks/inbox
 *
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<Response>}
 */
export const getUsersMeStreaksInbox = (limit, offset) => apiFetch(`${source}/users/me/streaks/inbox?limit=${limit}&offset=${offset}`, {
  headers: {
    ...getBearer(),
  },
});

/**
 * GET /users/me/streaks/history
 *
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<Response>}
 */
export const getUsersMeStreaksHistory = (limit, offset) => apiFetch(`${source}/users/me/streaks/history?limit=${limit}&offset=${offset}`, {
  headers: {
    ...getBearer(),
  },
});

/**
 * PATCH /users/me/streaks/{streakId}
 *
 * @param {number} id
 * @param {('accept'|'reject'|'cancel')} status
 * @returns {Promise<Response>}
 */
export const patchUsersMeStreaksById = (id, status) => apiFetch(`${source}/users/me/streaks/${id}`, {
  body: JSON.stringify({
    status,
  }),
  method: 'PATCH',
  headers: {
    ...getBearer(),
  },
});

/**
 * GET /users/me/streaks/stats
 *
 * @returns {Promise<Response>}
 */
export const getUsersMeStreaksStats = () => apiFetch(`${source}/users/me/streaks/stats`, {
  headers: {
    ...getBearer(),
  },
});

/**
 * POST /users/me/streaks/{streakId}/recovery/init
 *
 * @param streakId {number}
 * @param body {object}
 * @returns {Promise<Response>}
 */
export const postUsersMeStreaksRecoveryById = (streakId, body) => apiFetch(`${source}/users/me/streaks/${streakId}/recovery/init`, {
  method: 'POST',
  headers: {
    ...getBearer(),
  },
  body: JSON.stringify(body),
});

/**
 * POST /posts/{postId}/streaks
 *
 * @param postId {number}
 * @param petId {number}
 * @returns {Promise<Response>}
 */
export const postInvitePostToStreakById = (petId, postId) => apiFetch(`${source}/posts/${postId}/streaks`, {
  method: 'POST',
  headers: {
    ...getBearer(),
  },
  body: JSON.stringify({
    petId,
  }),
});

/**
 * POST /posts/{postId}/votes
 *
 * @param postId {number}
 * @returns {Promise<Response>}
 */
export const voteForPet = (postId) => apiFetch(`${source}/posts/${postId}/votes`, {
  method: 'POST',
  headers: {
    ...getBearer(),
  },
});

/**
 * POST /users/me/streaks/{streakId}/remind
 *
 * @param streakId {number}
 * @returns {Promise<Response>}
 */
export const remindAboutStreak = (streakId) => apiFetch(`${source}/users/me/streaks/${streakId}/remind`, {
  method: 'POST',
  headers: {
    ...getBearer(),
  },
});
