import { LOADING_STATES } from 'src/utils/maps';
import { LEADERBOARD_CONSTANTS } from 'src/modules/contest/constants/leaderboard';
import { newState } from 'src/utils';
import { produce } from 'immer';

const initialState = {
  currentOrder: 'dogs',
  contest: {},
  contestPets: [],
  nextLink: '',
  winners: { data: [] },
  showMoreLoading: false,
  firstLoad: true,
  searchID: null,
  winnersNextLink: true,
  status: LOADING_STATES.LOADING,
  page: 0,
};

const leaderboardReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LEADERBOARD_CONSTANTS.setCurrentOrder: {
      draft.currentOrder = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setContest: {
      draft.contest = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setContestPets: {
      draft.contestPets = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setNextLink: {
      draft.nextLink = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setWinners: {
      draft.winners = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setShowMoreLoading: {
      draft.showMoreLoading = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setFirstLoad: {
      draft.firstLoad = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setSearchID: {
      draft.searchID = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setWinnersNextLink: {
      draft.winnersNextLink = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setStatus: {
      draft.status = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.setPage: {
      draft.page = action.payload;
      break;
    }
    case LEADERBOARD_CONSTANTS.reset: {
      return initialState;
    }
    case LEADERBOARD_CONSTANTS.softReset: {
      return newState(state, {
        contestPets: [],
        nextLink: '',
        winners: { data: [] },
        showMoreLoading: false,
        winnersNextLink: true,
        page: 0,
        status: LOADING_STATES.LOADING,
      });
    }
    default: {
      break;
    }
  }
});

export default leaderboardReducer;
