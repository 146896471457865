import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';
import { useLocation } from 'react-router-dom';
import Popup from '../../../../components/Popup';
import s from './StreakCompleteAlert.module.scss';
import Button from '../../../../components/Form/Button';
import { setStreakCompletedAlertData, setStreakCompletedAlertOpened } from '../../../../actions/app';
import fireJson from '../../constants/fire.json';
import { formatNumberToTh } from '../../../../utils';
import { fetchMyStreaks, fetchStats, resetPupstreaks } from '../../actions/streaks';

const messages = defineMessages({
  currentAppliedBonus: {
    id: 'streaks.completeAlert.currentAppliedBonus',
    defaultMessage: 'Current applied bonus',
  },
  numDays: {
    id: 'streaks.completeAlert.numDays',
    defaultMessage: '{number} day',
  },
  numDaysToBonus: {
    id: 'streaks.completeAlert.numDaysToBonus',
    defaultMessage: '{number} days left',
  },
  streakComplete: {
    id: 'streaks.completeAlert.streakComplete',
    defaultMessage: 'Streak complete',
  },
  ok: {
    id: 'streaks.ok',
    defaultMessage: 'Ok',
  },
  helperText: {
    id: 'streaks.completeAlert.helperText',
    defaultMessage: 'You and <b>{name}</b> voted for each other and kept the streak alive, don’t forget to vote again tomorrow',
  },
  nextMegaLabel: {
    id: 'streaks.completeAlert.nextMegaLabel',
    defaultMessage: 'Next Mega bonus',
  },
  megaLabel: {
    id: 'streaks.completeAlert.megaLabel',
    defaultMessage: 'Mega <br /> bonus',
  },
  received: {
    id: 'streaks.completeAlert.received',
    defaultMessage: 'Received',
  },
});

const StreakCompleteAlert = () => {
  const { formatMessage } = useIntl();

  const completedStreak = useSelector(state => state.app.completedStreak);
  const dispatch = useDispatch();

  const countryLanguage = useSelector((state) => state.app?.currentCountry?.language);

  const location = useLocation();

  const handleClose = () => {
    dispatch(setStreakCompletedAlertOpened(false));

    if (location.pathname.includes('streaks/pupstreaks')) {
      dispatch(resetPupstreaks());
      dispatch(fetchStats());
      dispatch(fetchMyStreaks());
    }

    setTimeout(() => {
      dispatch(setStreakCompletedAlertData(null));
    }, 300);
  };

  if (!completedStreak.data) {
    return null;
  }

  const isBonusDay = completedStreak.data.currentBonus?.days % 30 === 0;

  return (
    <Popup
      open={completedStreak.opened}
      closeOnEsc
      disableBody
      onClose={handleClose}
      closeOnOverlay
      closeBtn
      wrapperClassname="modal-wrapper"
      paperClassname={s.paper}
    >
      <div className={s.wrapper}>
        <div className={s.row}>
          <div className={s.bonus}>
            <p className={s.bonusInfo} dangerouslySetInnerHTML={{ __html: formatMessage(messages.currentAppliedBonus) }} />
            <div className={s.bonusNumber}>
              <i>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.4357 9.54527C13.6205 7.71946 21.2414 12.1036 22.4653 14.2085C22.9653 15.0685 23.1045 16.2972 22.4411 17.0909C21.481 18.2396 17.5725 18.6219 16.178 19.4214C15.013 20.0893 12.6509 23.1748 11.2099 23.5296C10.0072 23.8258 9.04471 23.1791 8.48795 22.2215C7.2641 20.1166 7.25089 11.3711 10.4357 9.54527ZM1.01084 12.0697C2.01067 11.1624 3.84422 11.7255 5.10618 13.3274C6.36814 14.9294 6.58063 16.9635 5.58079 17.8708C4.58095 18.7781 2.7474 18.215 1.48545 16.613C0.223487 15.0111 0.0109969 12.977 1.01084 12.0697ZM15.9209 6.44943C15.2387 4.53976 15.7575 2.72807 17.0796 2.40291C18.4018 2.07776 20.0266 3.36226 20.7088 5.27193C21.3909 7.18161 20.8722 8.99329 19.55 9.31845C18.2279 9.64361 16.6031 8.3591 15.9209 6.44943ZM3.32468 4.12088C4.48788 3.45402 6.26559 4.34908 7.2953 6.12004C8.325 7.891 8.21678 9.86724 7.05358 10.5341C5.89037 11.2009 4.11267 10.3059 3.08296 8.53493C2.05325 6.76397 2.16147 4.78773 3.32468 4.12088ZM9.06878 0.827848C10.232 0.160995 12.0097 1.05605 13.0394 2.82701C14.0691 4.59797 13.9609 6.57421 12.7977 7.24106C11.6345 7.90791 9.85676 7.01286 8.82706 5.2419C7.79735 3.47094 7.90557 1.4947 9.06878 0.827848Z" fill="#50B772" />
                </svg>
              </i>
              <span>{completedStreak.data.currentBonus?.bonus ?? 0}</span>
            </div>
            <p className={s.bonusInfo}>
              {formatMessage(messages.numDays, { number: completedStreak.data.currentBonus?.days + formatNumberToTh(completedStreak.data.currentBonus?.bonus, countryLanguage) })}
            </p>
          </div>
          <div className={s.image}>
            <Player
              src={fireJson}
              background="transparent"
              speed={1}
              autoplay
            />
          </div>
          <div className={s.bonus}>
            <p className={s.bonusInfo} dangerouslySetInnerHTML={{ __html: formatMessage(isBonusDay ? messages.megaLabel : messages.nextMegaLabel) }} />
            <div className={classNames(s.bonusNumber, s.bonusNumberNext)}>
              <i>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.482 12.6016C17.912 8.52161 12.322 8.3016 13.672 2.37161C13.772 1.93161 13.302 1.59161 12.922 1.82161C9.29195 3.96161 6.68195 8.25161 8.87195 13.8716C9.05195 14.3316 8.51195 14.7616 8.12195 14.4616C6.31195 13.0916 6.12195 11.1216 6.28195 9.71161C6.34195 9.19161 5.66195 8.94161 5.37195 9.3716C4.69195 10.4116 4.00195 12.0916 4.00195 14.6216C4.38195 20.2216 9.11195 21.9416 10.812 22.1616C13.242 22.4716 15.872 22.0216 17.762 20.2916C19.842 18.3616 20.602 15.2816 19.482 12.6016ZM10.202 17.6316C11.642 17.2816 12.382 16.2416 12.582 15.3216C12.912 13.8916 11.622 12.4916 12.492 10.2316C12.822 12.1016 15.762 13.2716 15.762 15.3116C15.842 17.8416 13.102 20.0116 10.202 17.6316Z" fill="#FD784B" />
                </svg>
              </i>
              <span>{isBonusDay ? completedStreak.data.currentBonus.couponBonus : completedStreak.data.nextMegaBonus?.bonus ?? 0}</span>
            </div>
            <p className={s.bonusInfo}>{isBonusDay ? formatMessage(messages.received) : formatMessage(messages.numDaysToBonus, { number: completedStreak.data.nextMegaBonus?.daysLeft })}</p>
          </div>
        </div>
        <p className={s.title}>
          {completedStreak.data.title}
          {/* {formatMessage(messages.streakComplete)} */}
        </p>
        <p className={s.helperText} dangerouslySetInnerHTML={{ __html: completedStreak.data.description }} />
        <Button className="primary big" onClick={handleClose}>
          {formatMessage(messages.ok)}
        </Button>
      </div>
    </Popup>
  );
};

export default StreakCompleteAlert;
